import React from 'react'
import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import image from '../images/usluge_img_1.jpg'
class Media extends React.Component{
    render(){
        let posts = this.props.data.allWordpressPost.edges;
        let news_posts = [];
       posts.forEach(post => {
           if(post.node.categories[0].wordpress_id === 4){
               news_posts.push(post);
           }
       });
       const featuredPost = news_posts[0]
        return(
            <Layout>
            <SEO title="Media" keywords={[`telekomunikacije`, `inžinjering`]} />
            <div id="_media">
                <section className="section page-title">
                    <div className="container">
                        <h1 className="title">Novosti iz naše kompanije</h1>
                    </div>
                </section>

                <section id="_2nd" className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-6">
                                <div className="_innerBox">
                                <Link to={featuredPost.node.slug}><h3 dangerouslySetInnerHTML={{__html: featuredPost.node.title }} className="title" /></Link>
                                    <div className="content" dangerouslySetInnerHTML={{__html: featuredPost.node.excerpt}}  />
                                    <Link to={featuredPost.node.slug} className="_btnMore orange">Više <span className="icon-next"></span></Link>
                                </div>
                            </div>
                            <div className="column">
                                <img 
                                    src={featuredPost.node.featured_media.localFile.childImageSharp.resolutions.src}
                                    width={featuredPost.node.featured_media.localFile.childImageSharp.resolutions.width}
                                    height={featuredPost.node.featured_media.localFile.childImageSharp.resolutions.height}  alt={featuredPost.node.title} />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section page-content">
                    <div className="container">
                        <span className="_subtitle">Posljednje novosti</span>

                        {news_posts.slice(1).map(post => (
                            <div className="news-box columns">
                            <div className="image column is-4">
                                <Link to={post.node.slug}><img 
                                    src={post.node.featured_media.localFile.childImageSharp.resolutions.src}
                                    width={post.node.featured_media.localFile.childImageSharp.resolutions.width}
                                    height={post.node.featured_media.localFile.childImageSharp.resolutions.height}  alt={post.node.title} /></Link>
                            </div>
                            <div className="content column is-8">
                                <Link to={post.node.slug}><h1 className="title" dangerouslySetInnerHTML={{__html: post.node.title }} /></Link>
                                <div dangerouslySetInnerHTML={{__html: post.node.excerpt}}  />
                                <Link to={post.node.slug} className="_btnMore orange">Više <span className="icon-next"></span></Link>   
                            </div>
                        </div>/* COLUMNS NEWS BOX END */
                        ))}

                    </div>

                </section>
            </div>
            </Layout>
        );
    }
}

export default Media

export const pageQuery = graphql`
    query postsQueryNews{
        allWordpressPost{
            edges{
                node{
                    id
                    title
                    excerpt
                    slug
                    categories {
                        wordpress_id
                    }
                    date(formatString: "MMMM DD, YYYY")
                    featured_media{
                        localFile{
                            childImageSharp{
                                resolutions(width:600, height:400){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`